/* eslint-disable react/prop-types */
import * as React from "react";
import { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const initialValues = {
    client: "",
    category: "",
    title: "",
    contents: ""
};

export default function ListDialog(props) {
    const { open } = props;
    const [updateData, setUpdateData] = useState(initialValues);

    const handleClose = () => {
        props.onClose(false);
    };

    const [client, setClient] = React.useState('');

    const handleChange2 = (event) => {
        setClient(event.target.value);
    };

    useEffect(() => {
        if (props.buttonName === "Edit")
            setUpdateData(props.editData);
        else
            setUpdateData(initialValues);
    }, [props.editData]);

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.client) {
            errors.client = "client is required";
        } else if (values.client.length < 5) {
            errors.client = "client too short";
        }

        if (!values.category) {
            errors.categroy = "category is required";
        } else if (values.category.length < 2) {
            errors.category = "category too short";
        }

        if (!values.title) {
            errors.title = "title is required";
        } else if (values.title.length < 4) {
            errors.title = "title too short";
        }

        if (!values.contents) {
            errors.contents = "contents are required";
        } else if (values.contents.length < 10) {
            errors.contents = "contents too short";
        }
        return errors;
    };

    const UserDetailSchema = Yup.object().shape({

        title: Yup.string()
            .required("title is required")
            .min(4, "Too short - 4 chars minimum"),
        contents: Yup.string()
            .required("Cotents are required")
            .min(10, "Too short - 10 chars minimum")
    });

    const submitForm = (values) => {
        props.submitData(values);
        props.onClose(false);
    };

    const CustomizedSelectForFormik = ({ children, form, field }) => {
        const { name, value } = field;
        const { setFieldValue } = form;

        return (
            <Select
                name={name}
                value={value}
                onChange={e => {
                    setFieldValue(name, e.target.value);
                }}
            >
                {children}
            </Select>
        );
    };

    const clients = [
        {
          value: 'human',
          label: '인간',
        },
        {
          value: 'animal',
          label: '동물',
        },
      ];

    const categories = [
        {
          value: 'notice',
          label: '공지',
        },
        {
          value: 'update',
          label: '업데이트',
        },
        {
          value: 'maintenance',
          label: '점검',
        },
      ];
      
    return (
        <Dialog
            // style={{ content: EXAMPLE_ALERT_STYLE }}
            fullWidth={true}
            maxWidth={"md"}
            onClose={handleClose}
            open={open}
        >
            <DialogTitle>Notice Edit</DialogTitle>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "20px"
                }}
            >
                <div>
                    <Formik
                        initialValues={updateData}
                        // validate={validate}
                        validationSchema={UserDetailSchema}
                        onSubmit={submitForm}
                        enableReinitialize={true}
                    >
                        {(formik) => {
                            const {
                                values,
                                handleChange,
                                handleSubmit,
                                errors,
                                touched,
                                handleBlur,
                                isValid,
                                dirty
                            } = formik;
                            return (
                                <div className="container">
                                    <form onSubmit={handleSubmit}>
                                        {/* <div className="form-row">
                                            <TextField sx={{ m: 2 }}
                                                label="Client"
                                                variant="outlined"
                                                type="text"
                                                name="client"
                                                id="client"
                                                value={values.client}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={
                                                    errors.client && touched.client
                                                        ? "input-error"
                                                        : null
                                                }
                                                style={{ width: 600 }}
                                                margin="normal"
                                            />
                                            {errors.client && touched.client && (
                                                <span className="error">{errors.client}</span>
                                            )}
                                        </div> */}
                                        <div className="form-row">
                                            <TextField sx={{ m: 2 }}
                                                label="Client"
                                                variant="outlined"
                                                type="text"
                                                name="client"
                                                id="client"
                                                select
                                                defaultValue="human"
                                                helperText="Please select client"
                                                value={values.client}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                style={{ width: 600 }}
                                                margin="normal"
                                                >{clients.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                      {option.label}
                                                    </MenuItem>
                                                  ))}</TextField>
                                        </div>
                                        {/* <div className="form-row">
                                            <TextField sx={{ m: 2 }}
                                                label="Category"
                                                variant="outlined"
                                                type="text"
                                                name="category"
                                                id="category"
                                                value={values.category}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={
                                                    errors.category && touched.category
                                                        ? "input-error"
                                                        : null
                                                }
                                                style={{ width: 600 }}
                                                margin="normal"
                                            />
                                            {errors.category && touched.category && (
                                                <span className="error">{errors.category}</span>
                                            )}
                                        </div> */}
                                        <div className="form-row">
                                            <TextField sx={{ m: 2 }}
                                                label="Category"
                                                variant="outlined"
                                                type="text"
                                                name="category"
                                                id="category"
                                                select
                                                defaultValue="notice"
                                                helperText="Please select category"
                                                value={values.category}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                style={{ width: 600 }}
                                                margin="normal"
                                            >{categories.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                  {option.label}
                                                </MenuItem>
                                              ))}</TextField>
                                        </div>
                                        <div className="form-row">
                                            <TextField sx={{ m: 2 }}
                                                label="Title"
                                                variant="outlined"
                                                type="text"
                                                name="title"
                                                id="title"
                                                value={values.title}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={
                                                    errors.title && touched.title
                                                        ? "input-error"
                                                        : null
                                                }
                                                style={{ width: 600 }}
                                                margin="normal"
                                            />
                                            {errors.title && touched.title && (
                                                <span className="error">{errors.title}</span>
                                            )}
                                        </div>

                                        <div className="form-row">
                                            <TextField sx={{ m: 2 }}
                                                label="Contents"
                                                variant="outlined"
                                                type="contents"
                                                name="contents"
                                                id="contents"
                                                value={values.contents}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={
                                                    errors.contents && touched.contents ? "input-error" : null
                                                }
                                                multiline
                                                rows={10}
                                                style={{ width: 600 }}
                                            />
                                            {errors.contents && touched.contents && (
                                                <span className="error">{errors.contents}</span>
                                            )}
                                        </div>

                                        <Button sx={{ m: 2 }}
                                            type="submit"
                                            className={!(dirty && isValid) ? "disabled-btn" : ""}
                                            disabled={!(dirty && isValid)}
                                            variant="outlined"
                                        >
                                            {props.buttonName}
                                        </Button>
                                    </form>
                                </div>
                            );
                        }}
                    </Formik>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "0 10px 10px 0"
                    }}
                >
                </div>
            </div>
        </Dialog>
    );
}