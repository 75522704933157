/* eslint-disable react/prop-types */
import * as React from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import DataTable from 'react-data-table-component'
import { Divider } from '@mui/material';
import Title from './Title';
import { useState } from 'react'

function CustomModal({ isOpen, closeModal, children }) {

    return (
        <Modal open={isOpen} onClose={closeModal} slotProps={{ backdrop: { style: { backgroundColor: 'rgba(255,255,255,0.0)' } } }}>
            <Paper
                elevation={2}
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 1200,
                    maxWidth: "100%",
                    maxHeight: "90%",
                    overflowY: "auto",
                }}
            >
                {children}
                { /*
                <div>
                    <Title>Profile Information</Title>
                    <Divider />
                    <React.Fragment>
                        <DataTable
                            columns={profileColumns}
                            data={data}
                            noHeader
                            defaultSortField="id"
                            persistTableHead={true}
                        />
                    </React.Fragment>
                </div> */}
            </Paper>
        </Modal>
    );
}

export default CustomModal;