import * as React from "react";
import Typography from "@mui/material/Typography";
import Title from "./Title";
import axios from 'axios';
import DataTable from 'react-data-table-component'
import { useEffect } from 'react'
import { useState } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Divider } from '@mui/material';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CustomModal from "./CustomModal";

const Members = () => {
  const [queryData, setQueryData] = useState('')
  const [profileData, setProfileData] = useState('')
  const [profileTableData, setTableData] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenPets, setIsModalOpenPets] = useState(false);
  const [isModalOpenDevices, setIsModalOpenDevices] = useState(false);
  const openModal = id => {
    setIsModalOpen(true);
    getProfileData(id);
  }
  const openModalPets = id => {
    setIsModalOpenPets(true);
    getPetsData(id);
  }
  const openModalDevices = id => {
    setIsModalOpenDevices(true);
    getDevicesData(id);
  }
  const closeModal = () => setIsModalOpen(false);
  const closeModalPets = () => setIsModalOpenPets(false);
  const closeModalDevices = () => setIsModalOpenDevices(false);

  const [columnData, setColumn] = useState('');
  const [dataData, setData] = useState('');
  const [petsData, setPetsData] = useState('');
  const [devicesData, setDevicesData] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  // get profile data with given id
  const getProfileData = async (id) => {
    //const url = "https://dev-appio.precision-bio.com/console/users";
    const url = "/api/console/users/" + id + "/profiles";
    const config = { 'accept': 'application/hal+json' };

    try {
      const data = await axios.get(url, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      },
        config)
      console.log(data);
      console.log(url);
      //setProfileData({});
      setData({});
      //useEffect(() => {
      const timeout = setTimeout(() => {

        //setProfileData(data.data);
        //let tableData = [profileData,];

        setColumn(profileColumns);
        //setData([profileData,]);
        setData([data.data,]);
        console.log(columnData)
        console.log(dataData);
        setIsLoading(false);
      }, 100);
      return () => clearTimeout(timeout);

    } catch (error) {
      console.group(error.message);
    }
  }

  // get pets data with given id
  const getPetsData = async (id) => {
    //const url = "https://dev-appio.precision-bio.com/console/users";
    const url = "/api/console/users/" + id + "/pets";
    const config = { 'accept': 'application/hal+json' };

    try {
      const data = await axios.get(url, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      },
        config)
      console.log(data);
      setPetsData(data.data.pets);
      console.log(petsData);
    } catch (error) {
      console.group(error.message);
    }
  }

  // get devices data with given id
  const getDevicesData = async (id) => {
    //const url = "https://dev-appio.precision-bio.com/console/users";
    const url = "/api/console/users/" + id + "/devices";
    const config = { 'accept': 'application/hal+json' };

    try {
      const data = await axios.get(url, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      },
        config)
      console.log(data);
      setDevicesData(data.data.devices);
      console.log(devicesData);
    } catch (error) {
      console.group(error.message);
    }
  }

  // get list of member
  useEffect(() => {
    const getData = async () => {
      //const url = "https://dev-appio.precision-bio.com/console/users";
      const url = "/api/console/users";
      const params = {
        page: 1,
        size: 10,
        sort: "id",
      };
      const config = { 'accept': 'application/hal+json' };

      try {
        // const data = await axios.get(url, { params }, config);
        const data = await axios.get(url, {
          params: { page: 1, size: 10, sort: "id" },
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
          config
        })
        console.log(data);
        setQueryData(data.data.users);
        //console.log(queryData);
      } catch (error) {
        console.group(error.message);
      }
    }
    getData();
  }, []);

  // columns of profile information
  const profileColumns = [
    {
      name: 'ID',
      selector: row => row['id'],
      sortable: true,
      width: "4rem"
    },
    {
      name: 'User ID',
      selector: row => row["userId"],
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row["name"],
      sortable: true,
      width: "10rem"
    },
    {
      name: 'Email',
      selector: row => row["email"],
      sortable: true,
      width: "15rem"
    },
    {
      name: 'Birthday',
      selector: row => row["birth"],
      sortable: true,
    },
    {
      name: 'Gender',
      selector: row => row["gender"],
      sortable: true,
    },
    {
      name: 'Creation',
      selector: row => row["createdAt"],
      sortable: true,
      width: "10rem"
    },
    {
      name: 'Update',
      selector: row => row["updatedAt"],
      sortable: true,
      width: "10rem"
    },
    {
      name: 'Image URL',
      selector: row => row["imageUrl"],
      sortable: true,
      width: "30rem"
    },
  ]

  // columns of pets information
  const petsColumns = [
    {
      name: 'ID',
      selector: row => row['id'],
      sortable: true,
      width: "4rem"
    },
    {
      name: 'User ID',
      selector: row => row["userId"],
      sortable: true,
    },
    {
      name: 'Species',
      selector: row => row["species"],
      sortable: true,
    },
    {
      name: 'Breeds',
      selector: row => row["breeds"],
      sortable: true,
      width: "10rem"
    },
    {
      name: 'Name',
      selector: row => row["name"],
      sortable: true,
    },
    {
      name: 'Birthday',
      selector: row => row["birth"],
      sortable: true,
    },
    {
      name: 'Gender',
      selector: row => row["gender"],
      sortable: true,
    },
    {
      name: 'Neutered',
      selector: row => row["neutered"],
      sortable: true,
    },
    {
      name: 'Weight',
      selector: row => row["weight"],
      sortable: true,
    },
    {
      name: 'Creation',
      selector: row => row["createdAt"],
      sortable: true,
      width: "10rem"
    },
    {
      name: 'Update',
      selector: row => row["updatedAt"],
      sortable: true,
      width: "10rem"
    },
    {
      name: 'Image URL',
      selector: row => row["imageUrl"],
      sortable: true,
      width: "10rem"
    },
  ]

  // columns of profile information
  const deviceColumns = [
    {
      name: 'ID',
      selector: row => row['id'],
      sortable: true,
      width: "4rem"
    },
    {
      name: 'User ID',
      selector: row => row["userId"],
      sortable: true,
    },
    {
      name: 'Token',
      selector: row => row["token"],
      sortable: true,
    },
    {
      name: 'Platform',
      selector: row => row["platform"],
      sortable: true,
    },
    {
      name: 'Version',
      selector: row => row["version"],
      sortable: true,
    },
    {
      name: 'Alarm',
      selector: row => row["activeAlarm"],
      sortable: true,
    },
    {
      name: 'Alarm Time',
      selector: row => row["activeAlarmAt"],
      sortable: true,
      width: "10rem"
    },
    {
      name: 'Creation',
      selector: row => row["createdAt"],
      sortable: true,
      width: "10rem"
    },
    {
      name: 'Update',
      selector: row => row["updatedAt"],
      sortable: true,
      width: "10rem"
    },
  ]

  // columns of member list
  const columns = [
    {
      name: 'ID',
      selector: row => row["id"],
      sortable: true,
      width: "4rem"
    },
    {
      name: 'Client',
      selector: row => row["client"],
      sortable: true,
    },
    {
      name: 'Provider',
      selector: row => row["provider"],
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row["email"],
      sortable: true,
      width: "15rem",
    },
    {
      name: 'Name',
      selector: row => row["name"],
      sortable: true,
      width: "10rem",
    },
    {
      name: 'Last Login',
      selector: row => row["lastLoginAt"],
      sortable: true,
      width: "10rem"
    },
    {
      name: 'Creation',
      selector: row => row["createdAt"],
      sortable: true,
      width: "10rem"
    },
    {
      name: "Profiles",
      width: "7rem",
      //button: true,
      cell: (row) => (
        <div>
          {/* <Button onClick={openModal}>View</Button> */}
          <Button variant="outlined" onClick={() => { openModal(row.id) }}>View</Button>
          <CustomModal
            isOpen={isModalOpen}
            closeModal={closeModal}
          >              {!isLoading && (
            <div>
              <Title>Profile Information</Title>
              <Divider />
              <React.Fragment>
                <DataTable
                  columns={profileColumns}
                  data={dataData}
                  noHeader
                  persistTableHead={true}
                  progressPending={isLoading}
                />
              </React.Fragment>
            </div>)}
          </CustomModal>
        </div>
      ),
    },
    {
      name: "Pet Profiles",
      width: "7rem",
      //button: true,
      cell: (row) => (
        <div>
          {/* <Button onClick={openModal}>View</Button> */}
          <Button variant="outlined" onClick={() => { openModalPets(row.id) }}>View</Button>
          <CustomModal
            isOpen={isModalOpenPets}
            closeModal={closeModalPets}
          >
            <div>
              <Title>Pets Information</Title>
              <Divider />
              <React.Fragment>
                <DataTable
                  columns={petsColumns}
                  data={petsData}
                  noHeader
                  persistTableHead={true}
                />
              </React.Fragment>
            </div>
          </CustomModal>
        </div>
      ),
    },
    {
      name: "Mobile Devices",
      width: "8rem",
      //button: true,
      cell: (row) => (
        <div>
          {/* <Button onClick={openModal}>View</Button> */}
          <Button variant="outlined" onClick={() => { openModalDevices(row.id) }}>View</Button>
          <CustomModal
            isOpen={isModalOpenDevices}
            closeModal={closeModalDevices}
          >
            <div>
              <Title>Devices Information</Title>
              <Divider />
              <React.Fragment>
                <DataTable
                  columns={deviceColumns}
                  data={devicesData}
                  noHeader
                  persistTableHead={true}
                />
              </React.Fragment>
            </div>
          </CustomModal>
        </div>
      ),
    }
  ]

  const handleButtonClickOne = (e, id) => {
    e.preventDefault();
    console.log("Row Id", id);
  };

  const handleButtonClickTwo = (e, id) => {
    e.preventDefault();
    console.log("Row Id", id);
  };

  const handleButtonClickThree = (e, id) => {
    e.preventDefault();
    console.log("Row Id", id);
  };

  const tableData = {
    columns,
    queryData
  };

  const paginationRowsPerPageOptions = [10, 20, 30]; // Set your desired options

  return (
    <div>
      <Title>회원정보 관리</Title>
      <Divider />
      <React.Fragment>
        {/* <DataTableExtensions {...tableData}> */}
        <DataTable
          columns={columns}
          data={queryData}
          noHeader
          defaultSortField="id"
          sortIcon={<ArrowDownwardIcon />}
          defaultSortAsc={true}
          pagination
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          highlightOnHover
          // dense
          // selectableRows
          paginationPerPage={20}
          persistTableHead={true}
        // noDataComponent={<div>No previous versions of this document were found. No DATA NO DATA NO DATA</div>}
        />
        {/* </DataTableExtensions> */}
      </React.Fragment>
    </div>
  );
}

export default Members;