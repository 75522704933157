import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import axios from 'axios';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.precision-bio.com/">
                Precision Bio
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

function loginUser(credentials) {
    // return fetch('http://localhost:8080/login', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(credentials)
    // })
    //     .then(data => data.json())

    //const url = "https://dev-appio.precision-bio.com/v1/auth/signin";
    const url = "/api/v1/auth/signin";
    const data = { email: "admin@precision-bio.com", password: "admin" };
    const config = { 'Content-Type': 'application/json' };

    let res = "";
    axios.post(url, data, config).then(function (response) {
        // if(response.data.status == 201){
        //     console.log("Status 201");
        //     console.log(response);
        // } else {
        //     let message = response.data.message;
        //     if(response.data.code == 10000){
        //         message = "User ID is duplicated. Please enter a different User ID. "
        //     }
        //     console.log("error");
        //     console.log(response);
        // }
        console.log("Got Response")
        console.log(response.data?.tokens?.accessToken);
        res = response;
        console.log(res.data?.tokens?.accessToken);
        return res.data?.tokens?.accessToken;
    }).catch(function (error) {
        console.log(error);
    });
    // console.log(res.data?.tokens?.accessToken);
    // return res.data?.tokens?.accessToken;
}

export default function Login({ setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = event => {
        event.preventDefault();
        console.log("handleSumit");
        const data = new FormData(event.currentTarget);
        //console.log(data);
        setUserName(data.get('email'));
        setPassword(data.get('password'));
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
        // const token = loginUser({
        //     username,
        //     password
        //   });
        //   setToken(token);
        //   console.log(token);
        const url = "/api/v1/auth/signin";
        //const content = { email: "admin@precision-bio.com", password: "admin" };
        // const content = { email: `${username}`, password: `${password}` };
        const content = { email: `${data.get('email')}`, password: `${data.get('password')}` };
        const config = { 'Content-Type': 'application/json' };
        console.log(content);
        let res = "";
        axios.post(url, content, config).then(function (response) {
            console.log("Got Response")
            console.log(response.data?.tokens?.accessToken);
            res = response;
            console.log(res.data?.tokens?.accessToken);
            const token = res.data?.tokens?.accessToken;
            setToken(token);
            console.log(token);
        }).catch(function (error) {
            console.log(error);
        });
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};