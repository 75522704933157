/* eslint-disable react/prop-types */
import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DvrIcon from "@mui/icons-material/Dvr";
import SettingsIcon from "@mui/icons-material/Settings";
import AssignmentIcon from "@mui/icons-material/Assignment";
//import HomeIcon from "@mui/icons-material/Home";

function MainListItemsContent(props) {
  return (
    <React.Fragment>
      <ListItemButton
        onClick={() => {
          props.onClick(1);
        }}
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="관리자계정 관리" />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          props.onClick(2);
        }}
      >
        <ListItemIcon>
          <DvrIcon />
        </ListItemIcon>
        <ListItemText primary="회원정보 관리" />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          props.onClick(3);
        }}
      >
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="문의답변 관리" />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          props.onClick(4);
        }}
      >
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="공지사항 관리" />
      </ListItemButton>
    </React.Fragment>
  );
}

export default function MainListItems(props) {
  return <MainListItemsContent onClick={props.onClick} />;
}
