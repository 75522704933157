import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MainListItems from "./MainListItems";
import AdminAccount from "./component/AdminAccount";
import Members from "./component/Members";
import Inquiries from "./component/Inquiries";
import Notice from "./component/Notice";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import axios from 'axios';

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="https://www.precision-bio.com/">
                Precision-Bio.com
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9)
            }
        })
    }
}));

const mdTheme = createTheme();

function DashboardContent() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = e => {
        setAnchorEl(e.currentTarget);

    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [selectedTab, setTab] = React.useState(0);
    const selectTab = (i) => {
        console.log(i);
        setTab(i);
    };
    console.log(selectedTab);

    const logoutProc = () => {
        console.log("logoutProc called");

        //call logout API
        const url = "/api/v1/auth/logout";
        // const config = { 'Content-Type': 'application/json' };
        const data = {};
        const axiosConfig = {
            headers: {
                'accept': '*/*',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }
        let res = "";
        axios.post(url, data, axiosConfig).then(function (response) {
            res = response;
            console.log(res);
        }).catch(function (error) {
            console.log(error);
        });

        //delete token after calling logout api
        localStorage.removeItem('token');
        console.log("token removed");
        
        //reload page, go to login
        window.location.reload();
    };

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: "24px" // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: "36px",
                                ...(open && { display: "none" })
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            Console Dashboard
                        </Typography>
                        <div>
                            {/* <IconButton color="inherit" href="/Login"> */}
                            {/* <IconButton color="inherit" onClick={logoutProc} href="/Login"> */}
                            <IconButton color="inherit" onClick={logoutProc}>
                                <AccountCircleIcon />
                            </IconButton>
                            <div>logout</div>
                        </div>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            px: [1]
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        <MainListItems
                            onClick={
                                selectTab
                                //ToggleChart(true);
                                //toggleChart();
                                //selectTab(i);
                                //console.log("i");
                            }
                        />
                        <Divider sx={{ my: 1 }} />
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto"
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            {showTab(selectedTab)}
                        </Grid>
                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default function Dashboard() {
    return <DashboardContent />;
}

/*function ToggleChart(bool) {
  console.log("test " + bool);
  if (bool === true) {
    return <Chart />;
  }
  return null;
}*/
function showTab(tabNum) {
    if (tabNum === 1) {
        return <AdminAccount />;
    } else if (tabNum === 2) {
        return <Members />;
    } else if (tabNum === 3) {
        return <Inquiries />;
    } else if (tabNum === 4) {
        return <Notice />;
    } else
        return <AdminAccount />;
}
