/* eslint-disable react/prop-types */
import React from 'react';
import { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { Formik, Field, Form } from 'formik';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';

const initialValues = {
    contents: "",
    reply: "",
};

function ReplyDialog(props) {
    const [updateData, setUpdateData] = useState(initialValues);
    const { open } = props;

    const handleClose = () => {
        props.onClose(false);
    };

    const [client, setClient] = React.useState('');

    useEffect(() => {
        setUpdateData(props.editData);
    }, [props.editData]);
    console.log(props.editData);

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.reply) {
            errors.reply = "contents are required";
        }
        return errors;
    };

    const UserDetailSchema = Yup.object().shape({

        reply: Yup.string()
            .required("Reply is required")
            .min(4, "Too short - 4 chars minimum")
    });

    const submitForm = (values) => {
        props.submitData(values);
        props.onClose(false);
    };

    return (
        <Dialog
            // style={{ content: EXAMPLE_ALERT_STYLE }}
            fullWidth={true}
            maxWidth={"md"}
            onClose={handleClose}
            open={open}
        >
            <DialogTitle>Notice Edit</DialogTitle>
            <div>
                <Formik
                    onSubmit={submitForm}
                    initialValues={updateData}
                    // validate={validate}
                    validationSchema={UserDetailSchema}
                    enableReinitialize={true}
                >
                    {(formik) => {
                        const {
                            values,
                            handleChange,
                            handleSubmit,
                            errors,
                            touched,
                            handleBlur,
                            isValid,
                            dirty
                        } = formik;
                        return (
                            <div>
                                <form>
                                    <div className="form-row">
                                        <TextField sx={{ m: 2 }}
                                            label="Cotents"
                                            variant="outlined"
                                            type="text"
                                            name="contents"
                                            id="contents"
                                            value={values.contents}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={null}
                                            style={{ width: 600 }}
                                            margin="normal"
                                            multiline
                                            rows={10}
                                            disabled
                                        />

                                    </div>
                                    <div className="form-row">
                                        <TextField sx={{ m: 2 }}
                                            label="Reply"
                                            variant="outlined"
                                            type="text"
                                            name="reply"
                                            id="reply"
                                            value={values.reply}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                                errors.reply && touched.reply
                                                    ? "input-error"
                                                    : null
                                            }
                                            style={{ width: 600 }}
                                            margin="normal"
                                            multiline
                                            rows={10}
                                        />
                                        {errors.reply && touched.reply && (
                                            <span className="error">{errors.reply}</span>
                                        )}
                                    </div>
                                    <br />
                                    <Button sx={{ m: 2 }}
                                        type="submit"
                                        className={!(dirty && isValid) ? "disabled-btn" : ""}
                                        disabled={!(dirty && isValid)}
                                        variant="outlined"
                                    > Submit
                                    </Button>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </div>
        </Dialog>
    );
}
export default ReplyDialog;