import React from 'react';
import Dashboard from './Dashboard';
import Login from './component/App/Login';
import useToken from './component/App/useToken';

function App() {

  const { token, setToken } = useToken();

  if(!token) {
    console.log(token);
    return <Login setToken={setToken} />
  }

  return (
    <div>
      <Dashboard />
    </div>
  );
}

export default App;