import * as React from "react";
import Title from "./Title";
import Button from '@mui/material/Button';
import axios from 'axios';
import DataTable from 'react-data-table-component'
import { useEffect } from 'react'
import { useState } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ReplyDialog from './ReplyDialog';
import dayjs from 'dayjs';

function Inquiries() {
  const [open, setOpen] = useState(false);
  const [queryData, setQueryData] = useState('');
  const [replyData, setReplyData] = useState('')
  const [editData, setEditData] = useState({});
  const [btnName, setBtnName] = useState("Add");
  const [isModified, setModified] = useState(false);
  const [value, setValue] = React.useState(dayjs('2023-11-01'));
  const [value2, setValue2] = React.useState(dayjs('2024-12-31'));

  useEffect(() => {
    const getData = async () => {
      //const url = "https://dev-appio.precision-bio.com/console/help";
      const url = "/api/console/help";
      const config = { 'accept': 'application/hal+json' };
      //const headers = { 'Authorization': `Bearer ${localStorage.getItem('token')}` };
      console.log(value.format('YYYY-MM-DD'));
      console.log(value2.format('YYYY-MM-DD'));
      try {
        const data = await axios.get(url, {
          params: {
            // category: "notice", client: "human", sort: "id",
            createdAtStart: `${value.format('YYYY-MM-DD')}`, createdAtEnd: `${value2.format('YYYY-MM-DD')}`,
            page: 1, size: 20, sort: "id",
          },
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        }, config);
        setQueryData(data.data.help);
        //setQueryData(testData);
      } catch (error) {
        console.group(error.message);
      }
    }
    getData();
  }, []);

  // Edit Reply
  const patchData = async (newObj) => {
    //const url = "https://dev-appio.precision-bio.com/console/help";
    const url = "/api/console/help/" + newObj.id + "/reply";
    const axiosConfig = {
      headers: {
        'accept': 'application/hal+json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }
    // const title = newObj.title;
    // const content = newObj.contents;

    const postData = {
      reply: `${newObj.reply}`
    }
    console.log(postData);

    try {
      const data = await axios.patch(url,
        postData,
        axiosConfig);
      setReplyData(data.data);
    } catch (error) {
      console.group(error.message);
    }
    setModified(false);
  }

  const testData = [
    {
      'id': 1,
      'category': "help_01",
      'title': "Test Test",
      'contents': "Test Content",
      'reply': "No reply",
      'repliedAt': "2023-11-20",
      'createdAt': "2023-11-20",
    },
    {
      'id': 2,
      'category': "help_02",
      'title': "Test2 Test2",
      'contents': "Test Content22222",
      'reply': "No reply yet",
      'repliedAt': "2023-11-20",
      'createdAt': "2023-11-20",
    },
  ]
  const columns = [
    {
      name: 'ID',
      selector: row => row["id"],
      sortable: true,
      width: "5rem",
    },
    {
      name: 'Categroy',
      selector: row => row["category"],
      sortable: true,
    },
    {
      name: 'Title',
      selector: row => row["title"],
      width: "10rem",
    },
    {
      name: 'Contents',
      selector: row => row["contents"],
      width: "15rem",
    },
    {
      name: 'Reply',
      selector: row => row["reply"],
      width: "15rem",
    },
    {
      name: 'Reply Date',
      selector: row => row["repliedAt"],
      sortable: true,
      width: "10rem",
    },
    {
      name: 'Create Date',
      selector: row => row["createdAt"],
      sortable: true,
      width: "10rem",
    },
    {
      name: "Reply Edit",
      width: "7rem",
      cell: (param) => addDropDown(param)
    },
  ]
  //   useEffect(() => {
  //   //test
  //   setQueryData(testData);
  // }, []);

  // const handleClickOpen = () => {
  //   setBtnName("Add Reply");
  //   setEditData({});
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (data) => {
    console.log(data);

    setBtnName("Reply Edit");
    setOpen(true);
    setEditData(data);
  };

  const addDropDown = (param) => {
    return (
      <>
        <Button variant="outlined" onClick={() => handleEdit(param)}>
          Edit
        </Button>
      </>
    );
  };

  const submitData = (newObj) => {
    setModified(true);
    patchData(newObj);
    console.log("Edit button pressed");
    console.log(newObj);
  };

  const paginationRowsPerPageOptions = [10, 20, 30]; // Set your desired options

  return (
    <div>
      <React.Fragment>
        <Title>문의답변 관리</Title>
      </React.Fragment>

      <br />
      <React.Fragment>
        {/* <DataTableExtensions {...tableData}> */}
        <DataTable
          title="Inquiries Management"
          columns={columns}
          data={queryData}
          noHeader
          defaultSortField="id"
          sortIcon={<ArrowDownwardIcon />}
          defaultSortAsc={true}
          pagination
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          highlightOnHover
          // dense
          // selectableRows
          paginationPerPage={20}
          persistTableHead={true}
        />
        {/* </DataTableExtensions> */}
      </React.Fragment>
      <ReplyDialog
        buttonName={btnName}
        submitData={submitData}
        open={open}
        onClose={handleClose}
        editData={editData} />
    </div>
  );
}

export default Inquiries;