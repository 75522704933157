import * as React from "react";
import Title from "./Title";
import axios from 'axios';
import DataTable from 'react-data-table-component'
import { useEffect } from 'react'
import { useState } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import "react-data-table-component-extensions/dist/index.css";
import { Divider } from '@mui/material';
import Button from '@mui/material/Button';
import AdminDialog from "./AdminDialog";

const AdminAccount = () => {
  const [queryData, setQueryData] = useState('')
  axios.defaults.withCredentials = true;

  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [btnName, setBtnName] = useState("Add");
  const [isModified, setModified] = useState(false);

  // const getData = async () => {
  useEffect(() => {
    const getData = async () => {
      //const url = "https://dev-appio.precision-bio.com/console/admin";
      const url = "/api/console/admin";
      // const params = {
      //     page: 0,
      //     size: 1,
      //     sort: "id",
      // };
      const config = { 'accept': 'application/hal+json' };
      const headers = { 'Authorization': `Bearer ${localStorage.getItem('token')}` };
      console.log(headers);

      try {
        //const data = await axios.get(url, { params }, {headers}, config );
        const data = await axios.get(url, { params: { page: 1, size: 10, sort: "id" }, headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }, config);
        setQueryData(data.data.admin);
        console.log(data.data.admin);
      } catch (error) {
        console.group(error);
      }
    }
    getData();
  }, [isModified]);

    // Admin Account Update
    const patchData = async (newObj) => {
      //const url = "https://dev-appio.precision-bio.com/console/admin";
      const url = "/api/console/admin/" + newObj.id;
      const axiosConfig = {
        headers: {
          'accept': 'application/hal+json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }
  
      const postData = {
        name: `${newObj.name}`,
        department: `${newObj.department}`,
        password: `${newObj.password}`,
        role: `${newObj.role}`
      }
      console.log(postData);
  
      try {
        const data = await axios.patch(url,
          postData,
          axiosConfig);
        //setReplyData(data.data);
      } catch (error) {
        console.group(error.message);
      }
      setModified(false);
    }

  const columns = [
    {
      name: 'ID',
      selector: row => row["id"],
      sortable: true,
      width: "4rem"
    },
    // {
    //     name: 'User ID',
    //     selector: row => row["userId"],
    //     sortable: true,
    // },
    {
      name: 'Email',
      selector: row => row["email"],
      sortable: true,
      width: "14rem"
    },
    {
      name: 'Name',
      selector: row => row["name"],
      sortable: true,
    },
    {
      name: 'Role',
      selector: row => row["role"],
      sortable: true,
    },
    {
      name: 'Department',
      selector: row => row["department"],
      sortable: true,
      width: "8rem"
    },
    {
      name: 'Create Date',
      selector: row => row["createdAt"],
      sortable: true,
      width: "10rem"
    },
    {
      name: 'Update Date',
      selector: row => row["updatedAt"],
      sortable: true,
      width: "10rem"
    },
    // {
    //     name: "Details",
    //     button: true,
    //     cell: (row) => (
    //       <button
    //         className="btn profiles"
    //         onClick={(e) => handleButtonClickOne(e, row.id)}
    //       >
    //         View
    //       </button>
    //     ),},
    {
      name: "Update",
      cell: (param) => addDropDown(param)
    },
    {
      name: "Delete",
      width: "8rem",
      cell: (row) => (
        <Button variant="outlined"
          disabled={true}
          className="btn mobile evices"
          onClick={(e) => handleButtonClickThree(e, row.id)}
        >
          Delete
        </Button>
      ),
    }
  ]

  const handleButtonClickThree = (e, id) => {
    e.preventDefault();
    console.log("Row Id", id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (data) => {
    console.log(data);

    setBtnName("Reply Edit");
    setOpen(true);
    setEditData(data);
  };

  const addDropDown = (param) => {
    return (
      <>
        <Button variant="outlined" onClick={() => handleEdit(param)}>
          Edit
        </Button>
      </>
    );
  };

  const submitData = (newObj) => {
    setModified(true);
    patchData(newObj);
    console.log("Edit button pressed");
    console.log(newObj);
  };

  // const dataTemp = [{
  //     createdAt: "2023-11-19",
  //     department: "Admin",
  //     email: "admin@precision-bio.com",
  //     id: 1,
  //     name: "SuperUser",
  //     role: "admin",
  //     updatedAt: "2023-11-19",
  //     userId: 0
  // }, {
  //     createdAt: "2023-11-19",
  //     department: "Admin",
  //     email: "admin@precision-bio.com",
  //     id: 2,
  //     name: "SuperUser",
  //     role: "admin",
  //     updatedAt: "2023-11-19",
  //     userId: 0
  // }]

  //let tempData = Array.from(queryData);
  // const tableData = {
  //     columns,
  //     queryData,
  // };

  const paginationRowsPerPageOptions = [10, 20, 30]; // Set your desired options

  // console.log(tempData);
  // console.log(tableData);

  return (
    <div>
      <Title>Admin Account</Title>
      {/* <Typography component="p" variant="h5">
                contents
            </Typography> */}
      <Divider />

      <div>
        {/* <DataTableExtensions {...tableData}>  */}
        <DataTable
          columns={columns}
          data={queryData}
          noHeader
          defaultSortField="id"
          sortIcon={<ArrowDownwardIcon />}
          defaultSortAsc={false}
          pagination
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          highlightOnHover
          // dense
          // selectableRows
          paginationPerPage={10}
          persistTableHead={true}
        />
        {/* </DataTableExtensions> */}
      </div>
      <AdminDialog
        buttonName={btnName}
        submitData={submitData}
        open={open}
        onClose={handleClose}
        editData={editData} />
    </div>
  );
}

export default AdminAccount;