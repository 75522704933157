/* eslint-disable react/prop-types */
import React from 'react';
import { useState, useEffect } from "react";
import { Formik, Field, Form } from 'formik';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';

const initialValues = {
    name: "",
    department: "",
    password: "",
    role: "",
};

function AdminDialog(props) {
    const [updateData, setUpdateData] = useState(initialValues);
    const { open } = props;

    const handleClose = () => {
        props.onClose(false);
    };

    const [client, setClient] = React.useState('');

    useEffect(() => {
        setUpdateData(props.editData);
    }, [props.editData]);
    console.log(props.editData);

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.name) {
            errors.name = "name is required";
        } else if (values.name.length < 5) {
            errors.name = "name too short";
        }
        if (!values.department) {
            errors.department = "department is required";
        } else if (values.department.length < 5) {
            errors.department = "department too short";
        }
        if (!values.password) {
            errors.password = "password is required";
        } else if (values.password.length < 5) {
            errors.password = "password too short";
        }
        if (!values.role) {
            errors.role = "role is required";
        } else if (values.role.length < 5) {
            errors.role = "role too short";
        }

        return errors;
    };

    const UserDetailSchema = Yup.object().shape({
        name: Yup.string()
            .required("name is required")
            .min(4, "Too short - 4 chars minimum"),
        department: Yup.string()
            .required("department is required")
            .min(4, "Too short - 4 chars minimum"),
        password: Yup.string()
            .required("password is required")
            .min(4, "Too short - 4 chars minimum"),
        role: Yup.string()
            .required("role is required")
            .min(4, "Too short - 4 chars minimum")
    });

    const submitForm = (values) => {
        props.submitData(values);
        props.onClose(false);
    };

    return (
        <Dialog
            // style={{ content: EXAMPLE_ALERT_STYLE }}
            fullWidth={true}
            maxWidth={"md"}
            onClose={handleClose}
            open={open}
        >
            <DialogTitle>Admin Account Edit</DialogTitle>
            <div>
                <Formik
                    onSubmit={submitForm}
                    initialValues={updateData}
                    //validate={validate}
                    validationSchema={UserDetailSchema}
                    enableReinitialize={true}
                >
                    {(formik) => {
                        const {
                            values,
                            handleChange,
                            handleSubmit,
                            errors,
                            touched,
                            handleBlur,
                            isValid,
                            dirty
                        } = formik;
                        return (
                            <div className="container">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-row">
                                        <TextField sx={{ m: 2 }}
                                            label="Name"
                                            variant="outlined"
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                                errors.name && touched.name
                                                    ? "input-error"
                                                    : null
                                            }
                                            style={{ width: 600 }}
                                            margin="normal"
                                        />
                                        {errors.name && touched.name && (
                                            <span className="error">{errors.name}</span>
                                        )}
                                    </div>
                                    <div className="form-row">
                                        <TextField sx={{ m: 2 }}
                                            label="Department"
                                            variant="outlined"
                                            type="text"
                                            name="department"
                                            id="department"
                                            value={values.department}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                                errors.department && touched.department
                                                    ? "input-error"
                                                    : null
                                            }
                                            style={{ width: 600 }}
                                            margin="normal"
                                        />
                                        {errors.department && touched.department && (
                                            <span className="error">{errors.department}</span>
                                        )}
                                    </div>
                                    <div className="form-row">
                                        <TextField sx={{ m: 2 }}
                                            label="Password"
                                            variant="outlined"
                                            type="text"
                                            name="password"
                                            id="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                                errors.password && touched.password
                                                    ? "input-error"
                                                    : null
                                            }
                                            style={{ width: 600 }}
                                            margin="normal"
                                        />
                                        {errors.password && touched.password && (
                                            <span className="error">{errors.password}</span>
                                        )}
                                    </div>
                                    <div className="form-row">
                                        <TextField sx={{ m: 2 }}
                                            label="Role"
                                            variant="outlined"
                                            type="text"
                                            name="role"
                                            id="role"
                                            value={values.role}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                                errors.role && touched.role
                                                    ? "input-error"
                                                    : null
                                            }
                                            style={{ width: 600 }}
                                            margin="normal"
                                        />
                                        {errors.role && touched.role && (
                                            <span className="error">{errors.role}</span>
                                        )}
                                    </div>
                                    <br />
                                    <Button sx={{ m: 2 }}
                                        type="submit"
                                        className={!(dirty && isValid) ? "disabled-btn" : ""}
                                        disabled={!(dirty && isValid)}
                                        variant="outlined"
                                    > Submit
                                    </Button>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </div>
        </Dialog>
    );
}
export default AdminDialog;