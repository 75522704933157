import * as React from "react";
import dayjs from 'dayjs';
import Title from "./Title";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import axios from 'axios';
import DataTable from 'react-data-table-component'
import { useEffect } from 'react'
import { useState } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ListDialog from './ListDialog';

function Notice() {
  const [value, setValue] = React.useState(dayjs('2023-11-01'));
  const [value2, setValue2] = React.useState(dayjs('2024-12-31'));
  const [queryData, setQueryData] = useState('')

  const [open, setOpen] = useState(false);
  //const [newArr, setNewArr] = useState(jsonArr);
  const [editData, setEditData] = useState({});
  const [btnName, setBtnName] = useState("Add");
  const [isModified, setModified] = useState(false);

  useEffect(() => {
    const getData = async () => {
      //const url = "https://dev-appio.precision-bio.com/console/notifications";
      const url = "/api/console/notifications";
      const config = { 'accept': 'application/hal+json' };
      //const headers = { 'Authorization': `Bearer ${localStorage.getItem('token')}` };
      console.log(value.format('YYYY-MM-DD'));
      console.log(value2.format('YYYY-MM-DD'));
      try {
        const data = await axios.get(url, {
          params: {
            // category: "notice", client: "human", sort: "id",
            createdAtStart: `${value.format('YYYY-MM-DD')}`, createdAtEnd: `${value2.format('YYYY-MM-DD')}`,
            page: 1, size: 20, sort: "id",
          }, 
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        }, config);
        setQueryData(data.data.notifications);

      } catch (error) {
        console.group(error.message);
      }
      setModified(false);
    }
    getData();
  }, [editData, isModified]);

  // New Notice
  const postData = async (newObj) => {
    //const url = "https://dev-appio.precision-bio.com/console/notifications";
    const url = "/api/console/notifications";
    const axiosConfig = {
      headers: {
      'accept': 'application/hal+json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`}
    }
    const title = newObj.title;
    const category = newObj.category;
    const content = newObj.contents;
    const client = newObj.client;
    
    const postData = {
      category: category,
      client: client,
      title: title,
      contents: content,
    }

    console.log(postData);

    try {
      const data = await axios.post(url, 
        postData,
        axiosConfig);
      setQueryData(data.data.notifications);

    } catch (error) {
      console.group(error.message);
    }
    setModified(false);
  }

  // Edit Notice
  const patchData = async (newObj) => {
    //const url = "https://dev-appio.precision-bio.com/console/notifications";
    const url = "/api/console/notifications/"+newObj.id;
    const axiosConfig = {
      headers: {
      'accept': 'application/hal+json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`}
    }
    const title = newObj.title;
    const content = newObj.contents;
    
    const postData = {
      title: title,
      contents: content,
    }
    console.log(postData);

    try {
      //const data = await axios.patch(url,
      await axios.patch(url,
        postData,
        axiosConfig);
      //setQueryData(data.data.notifications);
    } catch (error) {
      console.group(error.message);
    }
    setModified(false);
  }
  const columns = [
    {
      name: 'ID',
      selector: row => row["id"],
      sortable: true,
      width: "5rem",
    },
    {
      name: 'Client',
      selector: row => row["client"],
      sortable: true,
    },
    {
      name: 'Category',
      selector: row => row["category"],
      width: "7rem",
    },
    {
      name: 'Title',
      selector: row => row["title"],
      width: "10rem",
    },
    {
      name: 'Contents',
      selector: row => row["contents"],
      width: "20rem",
    },
    {
      name: 'Create Date',
      selector: row => row["createdAt"],
      sortable: true,
      width: "10rem",
    },
    {
      name: 'Update Date',
      selector: row => row["updatedAt"],
      sortable: true,
      width: "10rem",
    },
    {
      name: "Notice Edit",
      width: "7rem",
      cell: (param) => addDropDown(param)
    },
  ]

  const handleClickOpen = () => {
    setBtnName("Add");
    setEditData({});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (data) => {
    console.log(data);

    setBtnName("Edit");
    setOpen(true);
    setEditData(data);
  };

  const addDropDown = (param) => {
    return (
      <>
        <Button variant="outlined" onClick={() => handleEdit(param)}>
          Edit
        </Button>
      </>
    );
  };
  const submitData = (newObj) => {

    if (btnName === "Edit") {
      patchData(newObj);
      console.log("Edit button pressed");
      console.log(newObj);
    } else {
      postData(newObj);
      console.log("Add button pressed");
      console.log(newObj);
    }
    setModified(true);
  };

  const paginationRowsPerPageOptions = [10, 20, 30]; // Set your desired options

  return (
    <div>
      <Title>공지사항 관리</Title>
      <Divider />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker', 'DatePicker']} sx={{ ml: 60 }}>
          <DatePicker label="From" value={value} onChange={(newValue) => setValue(newValue)} defaultValue={dayjs('2023-11-01')} slotProps={{ textField: { size: 'small' } }}/>
          <DatePicker label="To" value={value2} onChange={(newValue2) => setValue2(newValue2)} defaultValue={dayjs('2023-12-31')} slotProps={{ textField: { size: 'small' } }} />
          <Button variant="outlined" onClick={() => {
            // alert('clicked');
            setModified(true);
            //getData();
          }}>Query</Button>
        </DemoContainer>
      </LocalizationProvider>
      <br />
      <React.Fragment>
        {/* <DataTableExtensions {...tableData}> */}
        <DataTable
          title="Noitce Management"
          columns={columns}
          data={queryData}
          noHeader
          defaultSortField="id"
          sortIcon={<ArrowDownwardIcon />}
          defaultSortAsc={true}
          pagination
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          highlightOnHover
          // dense
          // selectableRows
          paginationPerPage={20}
          persistTableHead={true}
        />
        {/* </DataTableExtensions> */}
      </React.Fragment>
      <br />

      <Button variant="contained" onClick={handleClickOpen} sx={{ ml: 120 }}>
        New Notice
      </Button>
      <ListDialog
        buttonName={btnName}
        submitData={submitData}
        open={open}
        onClose={handleClose}
        editData={editData}
      />
    </div>
  );
}

export default Notice;
